@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }