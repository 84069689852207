.ul-tree {
    margin: 0;
    padding-left: 25px;
    line-height: 2em;
    list-style: none;
    background-color: #fff;
  }
  .ul-tree .li-tree {
    position: relative;
  }
  .ul-tree .li-tree:before {
    position: absolute;
    top: 0;
    left: -15px;
    display: block;
    width: 15px;
    height: 1em;
    content: "";
    border-bottom: 1px dotted #666;
    border-left: 1px dotted #666;
  }
  .ul-tree.tree > .li-tree:first-child:before {
    border-left: none;
  }
  .ul-tree .li-tree:after {
    position: absolute;
    top: 1.1em;
    bottom: 1px;
    left: -15px;
    display: block;
    content: "";
    border-left: 1px dotted #666;
  }
  
  .ul-tree .li-tree:last-child:after {
    display: none;
  }
  
  /* inserted via JS  */
  .js-toggle-icon {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 14px;
    margin-right: 2px;
    margin-left: -23px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #666;
    border-radius: 2px;
  }

  .menu-item {
    padding: 5px 10px;
  }
  
  .menu-item > a {
    display: block;
  }
  
  .menu-item > ul-tree {
    padding-left: 20px;
  }
  