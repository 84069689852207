.custom-class{
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-class li {
    visibility: hidden;    
}

.custom-class::before{
    content: ". . .";
    width: 100%;
    display: block;
    text-align: center;
    font-size: 2em;
    color: #333; /* Color de los puntos suspensivos */
    margin-bottom: 10px; /* Espacio entre los puntos y el contenido siguiente (si hay) */
    animation: blink 1.3s infinite; /* Hace que los puntos suspensivos parpadeen */
}

@keyframes blink {
  50% {
    opacity: 0; /* Hace que los puntos suspensivos desaparezcan en la mitad del tiempo de la animación */
  }
}  