.collapsible .content {
    margin-bottom: 1rem;
    
}

.collapsible .collapsible-content{
    height: 0px;
    overflow: hidden;
    transition: height ease 0.5s;
}

.sidebar {
    width: 64px;
    /* height: 91%; */
    background-color: #282828;
    transition-duration: 0.3s;
    /* display: flex; */
    /* flex-direction: column; */
    /* overflow: auto; */
    /* align-items: center; */
}
.sidebar .hamburger {
    width: 25px;
    margin-top: 20px;
    cursor: pointer;
    margin-left: 17.5px;
}

.sidebarOpen {
    width: 25%;
    /* height: 91%; */
    background-color: #282828;
    transition-duration: 0.3s;
    /* display: flex; */
    /* flex-direction: column; */
    /* overflow: auto; */
    /* align-items: center; */
}

.sidebarOpen a {
    text-decoration: none;
}
.sidebarOpen .hamburger {
    width: 200px;
    margin-top: 15px;
    cursor: pointer;
    margin-left: 17.5px;
}

.searchBar {
    transition-duration: 0.3s;
}

.searchBarOpen {
    transition-duration: 0.3s;
}

.content {
    transition-duration: 0.3s;
}

.contentOpen {
    transition-duration: 0.3s;
}

.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
}
  
.alert-hidden {
    opacity: 0;
    transition: all 250ms linear 1.2s;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10000;
    bottom: 150%;
    left: 70%;
    margin-left: -45px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

@media screen and (max-width: 500px) {
  .tooltip:hover .tooltiptext {
    visibility: hidden;
  }
}
